<template>
  <div>
    <div class="information" v-if="userInfo !== '{}'">
      <div class="informationTop">
        <span class="mationImg"><img src="@/assets/image/resume/icon.png" alt="" /></span>
        <span class="mationText">基础信息</span>
      </div>

      <div class="informationCentre">
        <div class="CentreMessage">
          <div class="MessageTop" v-if="userInfo && userInfo.resume_info">
            <span class="name">
              {{ userInfo.resume_info.name }}
            </span>
            <span class="gender" v-if="userInfo.resume_info.gender">
              {{ userInfo.resume_info.gender === 1 ? '男' : '女' }}
            </span>
            <span class="age" v-if="userInfo.resume_info.age">{{ userInfo.resume_info.age }}</span>
            <span class="education" v-if="userInfo.resume_info.degree_str">{{ userInfo.resume_info.degree_str }}</span>
            <span class="work" v-if="userInfo.resume_info.identity">
              {{
                userInfo.resume_info.identity === 1
                  ? '在校生'
                  : userInfo.resume_info.identity === 2
                  ? '应届生'
                  : '社会求职'
              }}
            </span>
          </div>
        </div>
        <!-- v-if="userInfo && userInfo.education_experience && userInfo.education_experience.length" -->
        <div class="CenterBottom" v-if="userInfo && userInfo.resume_info && userInfo.resume_info.school_name">
          学校名称：{{ userInfo.resume_info.school_name }}
        </div>
      </div>

      <div class="informationBottom" v-if="userInfo && userInfo.resume_info">
        <div class="informationOne">
          <div>
            手机号:
            <span>{{ userInfo.resume_info.phone_number }}</span>
          </div>
          <div>
            邮箱:
            <span>{{ userInfo.resume_info.email }}</span>
          </div>
        </div>
        <div class="informationOne">
          <div>
            <div>
              期望薪资:
              <span>{{ userInfo.resume_info.desired_salary }}</span>
            </div>
          </div>
          <div>
            期望行业:
            <span>{{ userInfo.resume_info.desired_industry }}</span>
          </div>
        </div>
        <div class="informationOne">
          <div>
            出生年月:
            <span>{{ userInfo.resume_info.date_of_birth }}</span>
          </div>
          <div>
            期望工作地区:
            <span>{{ userInfo.resume_info.expect_location }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    id: {
      type: String
    }
  },
  data() {
    return {
      userInfo: {}
    }
  },
  mounted() {
    this.getResumeDiagnosisReportFn()
  },
  methods: {
    ...mapActions(['getResumeDiagnosisReport']),
    async getResumeDiagnosisReportFn() {
      this.userInfo = await this.getResumeDiagnosisReport(this.id)
    }
  }
}
</script>

<style scoped lang="stylus">
.information {
  box-sizing: border-box
  margin-top: 30px
  width: 1118px
  height: 251px
  border: 1px solid #383A4D
  border-radius: 8px
  padding: 26px 0px 34px 35px

  .informationTop {
    display: flex
    align-items: center
    margin-bottom: 20px

    .mationImg {
      width: 15px
      height: 15px
      margin-right: 8px

      img {
        width: 100%
        height: 100%
      }
    }

    .mationText {
      font-size: 21px
      font-weight: bold
      color: #383A4D
    }
  }

  .informationCentre {
    margin-left: 22px

    .MessageTop {
      .name {
        font-size: 30px
        font-weight: bold
        color: #383A4D
        margin-right: 15px
      }

      .gender {
        display: inline-block
        font-size: 19px
        font-weight: 400
        color: #383A4D
        padding: 0 17px 0 0
        height: 12px
        position: relative
        margin-right: 15px
      }
      .gender:after {
        content: ''
        width: 1px
        height: 100%
        background: #383A4D
        position: absolute
        right: 2px
        top: 90%
        opacity: 0.5
      }

      .age {
        display: inline-block
        font-size: 19px
        font-weight: 400
        color: #383A4D
        margin-right: 15px
        height: 12px
        position: relative
        padding-right: 15px
      }

      .age:after {
        content: ''
        width: 1px
        height: 100%
        background: #383A4D
        position: absolute
        right: 1px
        top: 90%
        opacity: 0.5
      }

      .education {
        display: inline-block
        font-size: 19px
        font-weight: 400
        color: #383A4D
        position: relative
        height: 12px
        padding: 0 17px 0 0px
        margin-right: 15px
      }

      .education:after {
        content: ''
        width: 1px
        height: 100%
        background: #383A4D
        position: absolute
        right: 1px
        top: 90%
        opacity: 0.5
      }

      .work {
        font-size: 19px
        font-weight: 400
        color: #383A4D

      }
    }

    .CenterBottom {
      margin-top: 5px
      font-size: 19px
    }
  }

 .informationBottom {
      margin: 34px 150px 0 22px
      display: flex
      justify-content: space-between
      .informationOne {
        div {
          font-size: 19px
          font-weight: 400
          color: #878A99
          margin-bottom: 4px
          & span {
            display: inline-block
            color: #383A4D
          }
        }
      }
 }
}
</style>
