<template>
  <div class="Ending">
    <div class="EndingBox">
      <div class="EndingImg">
        <img src="@/assets/image/resume/二维码.png" alt="" />
      </div>
      <div class="EndingPape">
        <span class="SPan">-</span>
        <div class="report">广西梯子科技有限公司</div>
        <span class="SPan">-</span>
      </div>

      <div class="pageS">页码: {{ pages }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResumeReportFoot', //简历报告结尾
  props: {
    pages: {
      //页码值
      type: String
    }
  }
}
</script>

<style scoped lang="stylus">
.Ending {
  height: auto;
  margin-top: 15px;

  .EndingImg {
    width: 65px;
    height: 65px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .EndingBox {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .EndingPape {
      display: flex;
      justify-content: center;

      .SPan {
        font-size: 18px;
        line-height: 20px;
        color: #878A99;
      }

      .report {
        font-size: 16px;
        font-weight: 400;
        color: #878A99;
        margin: 0 8px;
      }
    }

    .pageS {
      font-size: 16px;
      font-weight: 400;
      color: #878A99;
    }
  }
}
</style>
